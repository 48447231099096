<template>
    <div
        class="row transition overflow-hidden"
        :style="`max-height: ${openChieuDi ? `1000px` : `32px`}`"
    >
        <div class="xs12">
            <div
                class="row align-center font-16 font-medium color-success mb-1"
                @click="openChieuDi = !openChieuDi"
            >
                <i
                    class="mdi mdi-menu-down-outline transition"
                    :style="`transform: rotate( ${openChieuDi ? `180` : `0`}deg);`"
                ></i>
                Xuất phát từ:
                {{
                    KyLenhTuyChon_ThongTinChung &&
                    KyLenhTuyChon_ThongTinChung.DanhSachLoTrinh
                        ? KyLenhTuyChon_ThongTinChung.DanhSachLoTrinh[1]?.TenBenXe
                        : ""
                }}
            </div>
            <DxValidationGroup ref="formValidation">
                <div class="row mb-4">
                    <div class="xs12" style="position: relative">
                        <DxTagBox
                            ref="GioXBKeHoachChieuVe"
                            label="Giờ XB kế hoạch (*)"
                            labelMode="floating"
                            v-model="KyLenhTuyChon_ThongTinChieuVe.GioXB"
                            :searchEnabled="true"
                            :acceptCustomValue="true"
                            :showDropDownButton="true"
                            :showClearButton="true"
                            :showSelectionControls="true"
                            styling-mode="underlined"
                            valueChangeEvent="keyup"
                            :onKeyUp="autoFillText"
                            :onCustomItemCreating="onCustomItemCreating"
                            validationMessageMode="always"
                            dropDownButtonTemplate="customIcon"
                        >
                            <DxValidator ref="validateNotTai">
                                <DxRequiredRule message="Giờ XB không được bỏ trống!" />
                            </DxValidator>
                            <template #customIcon="{}">
                                <DxButton icon="mdi mdi-calendar" />
                            </template>
                        </DxTagBox>
                        <DxButton
                            icon="mdi mdi-calendar"
                            style="position: absolute; right: 0; top: calc(50% - 18px)"
                            @click="ThemGioXuatBen()"
                        />
                        <DxDateBox
                            ref="ThemGioXuatBen"
                            label="Giờ XB kế hoạch (*)"
                            displayFormat="HH:mm"
                            type="time"
                            pickerType="rollers"
                            :use-mask-behavior="true"
                            dropDownButtonTemplate="customIcon"
                            validationMessageMode="always"
                            styling-mode="underlined"
                            :height="0"
                            :onValueChanged="onValueChangedThemGioXuatBen"
                            style="opacity: 0; position: absolute"
                        >
                            <template #customIcon="{}">
                                <DxButton icon="mdi mdi-calendar" />
                            </template>
                        </DxDateBox>

                        <!-- <DxDateBox
                            label="Giờ XB kế hoạch (*)"
                            v-model="KyLenhTuyChon_ThongTinChieuVe.GioXB"
                            displayFormat="HH:mm"
                            type="time"
                            pickerType="rollers"
                            :use-mask-behavior="true"
                            dropDownButtonTemplate="customIcon"
                            validationMessageMode="always"
                            styling-mode="underlined"
                        >
                            <template #customIcon="{}">
                                <DxButton icon="mdi mdi-calendar" />
                            </template>
                            <DxValidator>
                                <DxRequiredRule message="Giờ XB không được bỏ trống!" />
                            </DxValidator>
                        </DxDateBox> -->
                    </div>
                </div>

                <div class="row align-center mb-4">
                    <div class="xs12">
                        <DxSelectBox
                            v-model="KyLenhTuyChon_DanhSachLaiXeChieuVe[0]"
                            ref="LaiXeVe1"
                            label="Lái xe 1"
                            labelMode="floating"
                            :dataSource="{
                                store: KyLenhTuyChon_DanhSachLaiXe,
                                paginate: true,
                            }"
                            display-expr="HoTen"
                            :search-enabled="true"
                            :showClearButton="true"
                            styling-mode="underlined"
                            validationMessageMode="always"
                            item-template="item"
                            field-template="field"
                        >
                            <template #field="{ data }">
                                <DxTextBox
                                    :value="data ? data.HoTen : ``"
                                    :class="
                                        CheckDuDieuKienLaiXeVe_1 == 'HetHan'
                                            ? 'color-error-thong-tin-lai-xe'
                                            : CheckDuDieuKienLaiXeVe_1 == 'GanHetHan'
                                            ? 'color-warning-thong-tin-lai-xe'
                                            : ''
                                    "
                                />
                            </template>
                            <template #item="{ data }">
                                <div
                                    style="
                                        white-space: break-spaces;
                                        border-bottom: 1px solid #dadce0;
                                    "
                                >
                                    {{ data ? `${data.HoTen}` : "" }}
                                </div>
                            </template>
                            <DxValidator>
                                <DxRequiredRule
                                    v-if="
                                        !KyLenhTuyChon_DanhSachLaiXeChieuVe[1] &&
                                        !KyLenhTuyChon_DanhSachLaiXeChieuVe[2]
                                    "
                                    message="Lái xe không được bỏ trống!"
                                />
                                <DxCompareRule
                                    v-if="KyLenhTuyChon_DanhSachLaiXeChieuVe[2]"
                                    :comparisonTarget="
                                        () => {
                                            return KyLenhTuyChon_DanhSachLaiXeChieuVe[2];
                                        }
                                    "
                                    comparisonType="!="
                                    message="Thông tin lái xe không được trùng!"
                                />
                                <DxCompareRule
                                    v-if="KyLenhTuyChon_DanhSachLaiXeChieuVe[1]"
                                    :comparisonTarget="
                                        () => {
                                            return KyLenhTuyChon_DanhSachLaiXeChieuVe[1];
                                        }
                                    "
                                    comparisonType="!="
                                    message="Thông tin lái xe không được trùng!"
                                />
                            </DxValidator>
                        </DxSelectBox>
                    </div>

                    <DxButton
                        type="normal"
                        styling-mode="text"
                        icon="mdi mdi-pencil"
                        :disabled="!KyLenhTuyChon_DanhSachLaiXeChieuVe[0]"
                        @click="SuaLaiXe(KyLenhTuyChon_DanhSachLaiXeChieuVe[0])"
                    />
                    <DxButton
                        type="normal"
                        styling-mode="text"
                        icon="mdi mdi-sync"
                        @click="
                            CapNhatThongTin(
                                'LaiXe1',
                                'Bạn có muốn cập nhật thông tin Lái xe 1 từ chiều đi không?',
                            )
                        "
                    />
                </div>
                <div class="row align-center mb-4">
                    <div class="xs12">
                        <DxSelectBox
                            v-model="KyLenhTuyChon_DanhSachLaiXeChieuVe[1]"
                            ref="BienKiemSoat"
                            label="Lái xe 2"
                            labelMode="floating"
                            :dataSource="{
                                store: KyLenhTuyChon_DanhSachLaiXe,
                                paginate: true,
                            }"
                            display-expr="HoTen"
                            :search-enabled="true"
                            :showClearButton="true"
                            styling-mode="underlined"
                            validationMessageMode="always"
                            item-template="item"
                            field-template="field"
                        >
                            <template #field="{ data }">
                                <DxTextBox
                                    :value="data ? data.HoTen : ``"
                                    :class="
                                        CheckDuDieuKienLaiXeVe_2 == 'HetHan'
                                            ? 'color-error-thong-tin-lai-xe'
                                            : CheckDuDieuKienLaiXeVe_2 == 'GanHetHan'
                                            ? 'color-warning-thong-tin-lai-xe'
                                            : ''
                                    "
                                />
                            </template>
                            <template #item="{ data }">
                                <div
                                    style="
                                        white-space: break-spaces;
                                        border-bottom: 1px solid #dadce0;
                                    "
                                >
                                    {{ data ? `${data.HoTen}` : "" }}
                                </div>
                            </template>
                            <DxValidator>
                                <DxCompareRule
                                    v-if="KyLenhTuyChon_DanhSachLaiXeChieuVe[0]"
                                    :comparisonTarget="
                                        () => {
                                            return KyLenhTuyChon_DanhSachLaiXeChieuVe[0];
                                        }
                                    "
                                    comparisonType="!="
                                    message="Thông tin lái xe không được trùng!"
                                />
                                <DxCompareRule
                                    v-if="KyLenhTuyChon_DanhSachLaiXeChieuVe[2]"
                                    :comparisonTarget="
                                        () => {
                                            return KyLenhTuyChon_DanhSachLaiXeChieuVe[2];
                                        }
                                    "
                                    comparisonType="!="
                                    message="Thông tin lái xe không được trùng!"
                                />
                            </DxValidator>
                        </DxSelectBox>
                    </div>

                    <DxButton
                        type="normal"
                        styling-mode="text"
                        icon="mdi mdi-pencil"
                        :disabled="!KyLenhTuyChon_DanhSachLaiXeChieuVe[1]"
                        @click="SuaLaiXe(KyLenhTuyChon_DanhSachLaiXeChieuVe[1])"
                    />
                    <DxButton
                        type="normal"
                        styling-mode="text"
                        icon="mdi mdi-sync"
                        @click="
                            CapNhatThongTin(
                                'LaiXe2',
                                'Bạn có muốn cập nhật thông tin Lái xe 2 từ chiều đi không?',
                            )
                        "
                    />
                </div>
                <div class="row align-center mb-4">
                    <div class="xs12">
                        <DxSelectBox
                            v-model="KyLenhTuyChon_DanhSachLaiXeChieuVe[2]"
                            ref="BienKiemSoat"
                            label="Lái xe 3"
                            labelMode="floating"
                            :dataSource="{
                                store: KyLenhTuyChon_DanhSachLaiXe,
                                paginate: true,
                            }"
                            display-expr="HoTen"
                            :search-enabled="true"
                            :showClearButton="true"
                            styling-mode="underlined"
                            validationMessageMode="always"
                            item-template="item"
                            field-template="field"
                        >
                            <template #field="{ data }">
                                <DxTextBox
                                    :value="data ? data.HoTen : ``"
                                    :class="
                                        CheckDuDieuKienLaiXeVe_3 == 'HetHan'
                                            ? 'color-error-thong-tin-lai-xe'
                                            : CheckDuDieuKienLaiXeVe_3 == 'GanHetHan'
                                            ? 'color-warning-thong-tin-lai-xe'
                                            : ''
                                    "
                                />
                            </template>
                            <template #item="{ data }">
                                <div
                                    style="
                                        white-space: break-spaces;
                                        border-bottom: 1px solid #dadce0;
                                    "
                                >
                                    {{ data ? `${data.HoTen}` : "" }}
                                </div>
                            </template>
                            <DxValidator>
                                <DxCompareRule
                                    v-if="KyLenhTuyChon_DanhSachLaiXeChieuVe[0]"
                                    :comparisonTarget="
                                        () => {
                                            return KyLenhTuyChon_DanhSachLaiXeChieuVe[0];
                                        }
                                    "
                                    comparisonType="!="
                                    message="Thông tin lái xe không được trùng!"
                                />
                                <DxCompareRule
                                    v-if="KyLenhTuyChon_DanhSachLaiXeChieuVe[1]"
                                    :comparisonTarget="
                                        () => {
                                            return KyLenhTuyChon_DanhSachLaiXeChieuVe[1];
                                        }
                                    "
                                    comparisonType="!="
                                    message="Thông tin lái xe không được trùng!"
                                />
                            </DxValidator>
                        </DxSelectBox>
                    </div>

                    <DxButton
                        type="normal"
                        styling-mode="text"
                        icon="mdi mdi-pencil"
                        :disabled="!KyLenhTuyChon_DanhSachLaiXeChieuVe[2]"
                        @click="SuaLaiXe(KyLenhTuyChon_DanhSachLaiXeChieuVe[2])"
                    />
                    <DxButton
                        type="normal"
                        styling-mode="text"
                        icon="mdi mdi-sync"
                        @click="
                            CapNhatThongTin(
                                'LaiXe3',
                                'Bạn có muốn cập nhật thông tin Lái xe 3 từ chiều đi không?',
                            )
                        "
                    />
                </div>

                <div class="row align-center mb-4">
                    <div class="xs12">
                        <DxTextBox
                            label="Phụ xe"
                            labelMode="floating"
                            v-model:value="KyLenhTuyChon_ThongTinChieuVe.PhuXe"
                            styling-mode="underlined"
                        >
                            <DxValidator>
                                <DxStringLengthRule
                                    :max="48"
                                    message="Phụ xe không được lớn hơn 48 ký tự!"
                                />
                            </DxValidator>
                        </DxTextBox>
                    </div>
                    <DxButton
                        type="normal"
                        styling-mode="text"
                        icon="mdi mdi-sync"
                        @click="
                            CapNhatThongTin(
                                'PhuXe',
                                'Bạn có muốn cập nhật thông tin Phụ xe từ chiều đi không?',
                            )
                        "
                    />
                </div>
            </DxValidationGroup>

            <div
                class="row align-center mb-3"
                style="
                    border: 2px solid #03a9f4;
                    border-radius: 6px;
                    padding: 8px 6px;
                    box-shadow: 0px 3px 5px #ccc;
                "
            >
                <div class="xs12 font-medium" style="white-space: break-spaces">
                    - Ngày chạy:
                    <span
                        :class="
                            item.NgayTrongTuan == 0
                                ? 'color-error'
                                : item.NgayTrongTuan == 6
                                ? 'color-warning'
                                : 'color-primary'
                        "
                        v-for="(
                            item, index
                        ) in KyLenhTuyChon_DanhSachNgayKyLenhDangChonChieuVe"
                        :key="index"
                        >{{
                            `\n+ ${calculateNgayTrongTuan(
                                item.NgayTrongTuan,
                            )} (${$Helper.Common.formatDate(item.NgayDuong)} - ${
                                item.NgayAm
                            })`
                        }}</span
                    >
                </div>
                <DxButton
                    type="normal"
                    styling-mode="text"
                    icon="mdi mdi-pencil"
                    @click="$router.push('/Tao-Lenh-Tuy-Chon/Sua-Ngay-Chay-Chieu-Ve')"
                />
            </div>
        </div>
    </div>

    <!-- Xác nhận cập nhật thông tin -->
    <PopupVue
        height="auto"
        v-if="ifPopupXacNhanCapNhatThongTin"
        v-model:dialog="PopupXacNhanCapNhatThongTin"
        title=""
        buttonTextLeft="Hủy"
        buttonTextRight="Đồng ý"
        class="popup-padding-8"
        @close="XacNhanCapNhatThongTin"
        heightScrollView="auto"
    >
        <template #content>
            <ThongBaoVue
                :message="ParamThongBao.message"
                :state="ParamThongBao.state"
                :title="ParamThongBao.title"
            />
        </template>
    </PopupVue>
</template>
<script>
import { DxValidationGroup } from "devextreme-vue/validation-group";
import {
    DxValidator,
    DxSelectBox,
    DxButton,
    DxDateBox,
    DxTagBox,
    DxTextBox,
} from "devextreme-vue";
import {
    DxCompareRule,
    DxRequiredRule,
    DxStringLengthRule,
} from "devextreme-vue/data-grid";
import PopupVue from "../../../../components/_Common/Popup.vue";
import ThongBaoVue from "../../../../components/_Common/ThongBao.vue";
export default {
    components: {
        DxValidationGroup,
        DxTextBox,
        DxValidator,
        DxSelectBox,
        DxButton,
        DxDateBox,
        DxTagBox,
        DxCompareRule,
        DxRequiredRule,
        DxStringLengthRule,
        PopupVue,
        ThongBaoVue,
    },
    props: {},
    data() {
        return {
            openChieuDi: true,
            ifPopupXacNhanCapNhatThongTin: false,
            PopupXacNhanCapNhatThongTin: false,
            ParamThongBao: {
                state: "Warning",
                title: "Xác nhận chuyển đổi lệnh",
                message: "",
            },
        };
    },
    computed: {
        LenhStore() {
            return this.$store.state.LenhTuyChon;
        },
        KyLenhTuyChon_ThongTinChung: {
            get() {
                return this.LenhStore.KyLenhTuyChon_ThongTinChung || {};
            },
            set(data) {
                this.$store.commit("LenhTuyChon/Set", {
                    key: "KyLenhTuyChon_ThongTinChung",
                    data: data,
                });
            },
        },
        KyLenhTuyChon_ThongTinChieuDi: {
            get() {
                return this.LenhStore.KyLenhTuyChon_ThongTinChieuDi || {};
            },
            set(data) {
                this.$store.commit("LenhTuyChon/Set", {
                    key: "KyLenhTuyChon_ThongTinChieuDi",
                    data: data,
                });
            },
        },
        KyLenhTuyChon_ThongTinChieuVe: {
            get() {
                return this.LenhStore.KyLenhTuyChon_ThongTinChieuVe || {};
            },
            set(data) {
                this.$store.commit("LenhTuyChon/Set", {
                    key: "KyLenhTuyChon_ThongTinChieuVe",
                    data: data,
                });
            },
        },
        KyLenhTuyChon_DanhSachLaiXe() {
            return this.LenhStore.KyLenhTuyChon_DanhSachLaiXe || [];
        },
        KyLenhTuyChon_DanhSachLaiXeChieuDi: {
            get() {
                return this.LenhStore.KyLenhTuyChon_DanhSachLaiXeChieuDi || {};
            },
            set(data) {
                this.$store.commit("LenhTuyChon/Set", {
                    key: "KyLenhTuyChon_DanhSachLaiXeChieuDi",
                    data: data,
                });
            },
        },
        KyLenhTuyChon_DanhSachLaiXeChieuVe: {
            get() {
                return this.LenhStore.KyLenhTuyChon_DanhSachLaiXeChieuVe || {};
            },
            set(data) {
                this.$store.commit("LenhTuyChon/Set", {
                    key: "KyLenhTuyChon_DanhSachLaiXeChieuVe",
                    data: data,
                });
            },
        },
        KyLenhTuyChon_DanhSachNgayKyLenhDangChonChieuVe: {
            get() {
                return (
                    this.$store.state.LenhTuyChon
                        .KyLenhTuyChon_DanhSachNgayKyLenhDangChonChieuVe || []
                );
            },
            set(data) {
                this.$store.commit("LenhTuyChon/Set", {
                    key: "KyLenhTuyChon_DanhSachNgayKyLenhDangChonChieuVe",
                    data: data,
                });
            },
        },
        KyLenhTuyChon_ThongTinSuaLaiXe: {
            get() {
                return this.LenhStore.KyLenhTuyChon_ThongTinSuaLaiXe || {};
            },
            set(data) {
                this.$store.commit("LenhTuyChon/Set", {
                    key: "KyLenhTuyChon_ThongTinSuaLaiXe",
                    data: data,
                });
            },
        },
        CheckDuDieuKienLaiXeVe_1() {
            return this.$store.getters["LenhTuyChon/CheckDuDieuKienLaiXeVe_1"];
        },
        CheckDuDieuKienLaiXeVe_2() {
            return this.$store.getters["LenhTuyChon/CheckDuDieuKienLaiXeVe_2"];
        },
        CheckDuDieuKienLaiXeVe_3() {
            return this.$store.getters["LenhTuyChon/CheckDuDieuKienLaiXeVe_3"];
        },
    },
    watch: {
        PopupXacNhanCapNhatThongTin() {
            if (!this.PopupXacNhanCapNhatThongTin) {
                this.NgatDOMPopup("ifPopupXacNhanCapNhatThongTin");
            }
        },
    },
    methods: {
        showPopup(name) {
            this["if" + name] = true;
            this[name] = true;
        },
        NgatDOMPopup(key) {
            setTimeout(() => {
                this[key] = false;
            }, 300);
        },
        calculateNgayTrongTuan(NgayTrongTuan) {
            return NgayTrongTuan == 0 ? "Chủ nhật" : "Thứ " + (NgayTrongTuan + 1);
        },
        autoFillText(e) {
            if (e.event.currentTarget.value.length == 2) {
                e.event.currentTarget.value += ":";
            }
            if (e.event.currentTarget.value.length == 5) {
                let elmInput = e.element.querySelector("input");
                const event = new KeyboardEvent("keydown", {
                    key: "Enter",
                    keyCode: 13,
                    bubbles: true,
                    cancelable: true,
                });
                elmInput.dispatchEvent(event);
            }
        },
        onCustomItemCreating(e) {
            if (!e.text) {
                e.customItem = null;
                return;
            }
            var checkState = this.validateNotTai({ value: e.text });
            if (!checkState) {
                e.customItem = null;
                return this.$Helper.ThongBaoToast(
                    "warning",
                    "Nốt/Tài sai định dạng (HH:mm)!",
                );
            }
            let checkTrung = this.KyLenhTuyChon_ThongTinChieuVe.GioXB.some(
                (gio) => gio == e.text,
            );
            if (checkTrung) {
                e.customItem = null;
                return;
            }
            if (this.KyLenhTuyChon_ThongTinChieuVe.GioXB.length == 10) {
                e.customItem = null;
                return this.$Helper.ThongBaoToast(
                    "warning",
                    "Được phép ký tối đa 10 Nốt/Tài!",
                );
            }
            e.customItem = e.text;
        },
        validateNotTai(e) {
            var checkState = false;
            if (e.value.length == 5) {
                var data = e.value.split(":");
                if (data.length == 2) {
                    let data0 = parseInt(data[0]);
                    let data1 = parseInt(data[1]);
                    let check0 = data0 >= 0 && data0 < 24;
                    let check1 = data1 >= 0 && data1 < 60;
                    if (!!check0 && !!check1) {
                        checkState = true;
                    }
                }
            }
            return checkState;
        },
        ThemGioXuatBen() {
            this.$refs.ThemGioXuatBen.instance.reset();
            this.$refs.ThemGioXuatBen.instance.open();
        },
        onValueChangedThemGioXuatBen(e) {
            if (e.value) {
                let GioXB = this.$Helper.Common.formatTime(e.value);
                let checkTrung = this.KyLenhTuyChon_ThongTinChieuVe.GioXB.some(
                    (gio) => gio == GioXB,
                );
                if (!checkTrung) {
                    this.KyLenhTuyChon_ThongTinChieuVe.GioXB.push(GioXB);
                    this.$refs.GioXBKeHoachChieuVe.instance.repaint();
                }
            }
        },
        onItemClickLaiXe1(e) {
            if (!this.KyLenhTuyChon_DanhSachLaiXeChieuDi[0]) {
                this.KyLenhTuyChon_DanhSachLaiXeChieuDi[0] = this.KyLenhTuyChon_DanhSachLaiXe.find(
                    (lx) => lx.ID_LaiXe == e.itemData.ID_LaiXe,
                );
            }
        },
        onItemClickLaiXe2(e) {
            if (!this.KyLenhTuyChon_DanhSachLaiXeChieuDi[1]) {
                this.KyLenhTuyChon_DanhSachLaiXeChieuDi[1] = this.KyLenhTuyChon_DanhSachLaiXe.find(
                    (lx) => lx.ID_LaiXe == e.itemData.ID_LaiXe,
                );
            }
        },
        onItemClickLaiXe3(e) {
            if (!this.KyLenhTuyChon_DanhSachLaiXeChieuDi[2]) {
                this.KyLenhTuyChon_DanhSachLaiXeChieuDi[2] = this.KyLenhTuyChon_DanhSachLaiXe.find(
                    (lx) => lx.ID_LaiXe == e.itemData.ID_LaiXe,
                );
            }
        },
        CapNhatThongTin(key, title, message) {
            this.KeyCapNhatThongTin = key;
            this.ParamThongBao.message = message;
            this.ParamThongBao.title = title;
            this.showPopup("PopupXacNhanCapNhatThongTin");
        },
        XacNhanCapNhatThongTin(bool) {
            if (bool == false) {
                this.PopupXacNhanCapNhatThongTin = false;
            } else if (bool == true) {
                this.PopupXacNhanCapNhatThongTin = false;
                if (this.KeyCapNhatThongTin == "LaiXe1") {
                    if (!this.KyLenhTuyChon_DanhSachLaiXeChieuDi[0]) {
                        this.$Helper.ThongBaoToast(
                            "error",
                            `Lái xe 1 chiều đi đang không có dữ liệu!`,
                        );
                    } else {
                        this.KyLenhTuyChon_DanhSachLaiXeChieuVe[0] = this.KyLenhTuyChon_DanhSachLaiXe.find(
                            (lx) =>
                                lx.ID_LaiXe ==
                                this.KyLenhTuyChon_DanhSachLaiXeChieuDi[0].ID_LaiXe,
                        );
                    }
                }
                if (this.KeyCapNhatThongTin == "LaiXe2") {
                    if (!this.KyLenhTuyChon_DanhSachLaiXeChieuDi[1]) {
                        this.$Helper.ThongBaoToast(
                            "error",
                            `Lái xe 2 chiều đi đang không có dữ liệu!`,
                        );
                    } else {
                        this.KyLenhTuyChon_DanhSachLaiXeChieuVe[1] = this.KyLenhTuyChon_DanhSachLaiXe.find(
                            (lx) =>
                                lx.ID_LaiXe ==
                                this.KyLenhTuyChon_DanhSachLaiXeChieuDi[1].ID_LaiXe,
                        );
                    }
                }
                if (this.KeyCapNhatThongTin == "LaiXe3") {
                    if (!this.KyLenhTuyChon_DanhSachLaiXeChieuDi[2]) {
                        this.$Helper.ThongBaoToast(
                            "error",
                            `Lái xe 3 chiều đi đang không có dữ liệu!`,
                        );
                    } else {
                        this.KyLenhTuyChon_DanhSachLaiXeChieuVe[2] = this.KyLenhTuyChon_DanhSachLaiXe.find(
                            (lx) =>
                                lx.ID_LaiXe ==
                                this.KyLenhTuyChon_DanhSachLaiXeChieuDi[2].ID_LaiXe,
                        );
                    }
                }
                if (this.KeyCapNhatThongTin == "PhuXe") {
                    if (!this.KyLenhTuyChon_ThongTinChieuDi.PhuXe) {
                        this.$Helper.ThongBaoToast(
                            "error",
                            `Phụ xe chiều đi đang không có dữ liệu!`,
                        );
                    } else {
                        this.KyLenhTuyChon_ThongTinChieuVe.PhuXe = this.KyLenhTuyChon_ThongTinChieuDi.PhuXe;
                    }
                }
            }
        },
        SuaLaiXe(item) {
            this.KyLenhTuyChon_ThongTinSuaLaiXe.TenLaiXe = item.HoTen;
            this.KyLenhTuyChon_ThongTinSuaLaiXe.IdLaiXe = item.ID_LaiXe;
            this.KyLenhTuyChon_ThongTinSuaLaiXe.IdDnvtLaiXe = item.ID_DnvtLaiXe;
            this.KyLenhTuyChon_ThongTinSuaLaiXe.HangGPLX = item.HangGiayPhep;
            this.KyLenhTuyChon_ThongTinSuaLaiXe.HanHieuLucGPLX = new Date(
                item.HanGiayPhep,
            );
            this.KyLenhTuyChon_ThongTinSuaLaiXe.NgayKetThucHopDong = new Date(
                item.NgayKetThucHopDong,
            );

            this.KyLenhTuyChon_ThongTinSuaLaiXe.NgayBatDauHopDong = new Date(
                item.NgayBatDauHopDong,
            );
            this.$router.push("/Tao-Lenh-Tuy-Chon/Sua-Thong-Tin-Lai-Xe");
        },
    },
    created() {},
    mounted() {},
};
</script>
<style scoped>
:deep(.color-error-thong-tin-lai-xe input.dx-texteditor-input) {
    color: #d10909;
}
:deep(.color-warning-thong-tin-lai-xe input.dx-texteditor-input) {
    color: #fb8c00;
}
</style>
